<template>
  <div class="h1">Redicect Page</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { isEmpty } from 'lodash';
import { getCookie } from '../helpers/util';

export default {
  name: 'UserLogin',

  mounted() {
    this.$nextTick(async () => {
      // document.cookie = '_ga=GA1.1.1862742965.1731669081';
      // document.cookie = '_ga_ZNLSFDGQ6Q=GS1.1.1731669081.1.0.1731669086.55.0.0';
      // document.cookie =
      //   'acs-token=eyJwcm9maWxlIjp7Il9pZCI6IjY3MzY5ZjFjNzgxMmVmZDRmNzEzZGZmYiIsInVzZXJuYW1lIjoic2lhbS53YW5uYWtvc2l0QHRydWVjb3JwLmNvLnRoIiwiZnVsbE5
      // hbWUiOiJTaWFtLnciLCJwYXNzd29yZCI6IiQyYiQxMiR5Y0tjS2JsM1NyRUJFWFZDWHM1R0hPZG1SMzY3WHVyYkx5Z1FRUjFINVcvRDBHRS9LTUVWYSIsImRlcGFydG1lbnQiOm51bGw
      // sInJvbGVzIjpbIjAiXSwiYWN0aXZhdGVkIjp0cnVlLCJjcmVhdGVUaW1lIjoxNzMxNjMyOTI0MTEzLCJ1cGRhdGVUaW1lIjoxNzMxNjMyOTI0MTEzLCJfX3YiOjAsInNjb3BlcyI6WyJh
      // ZG1pbi5jcmVhdGUiLCJhZG1pbi5lZGl0IiwiYWRtaW4udmlldyIsImFkbWluLmNhbmNlbCIsImN1c3RvbWVyLmNyZWF0ZSIsImN1c3RvbWVyLmVkaXQiLCJjdXN0b21lci52aWV3IiwiY3
      // VzdG9tZXIuY2FuY2VsIiwiam9iLmNyZWF0ZSIsImpvYi5lZGl0Iiwiam9iLnZpZXciLCJqb2IuY2FuY2VsIiwicmVwb3J0LmNyZWF0ZSIsInJlcG9ydC5lZGl0IiwicmVwb3J0LnZpZXci
      // LCJyZXBvcnQuY2FuY2VsIl0sInJvbGVzRGVzY3JpcHRpb24iOlt7Il9pZCI6IjAiLCJuYW1lIjoiQWRtaW5pc3RyYXRvciBSb2xlIn1dLCJkZXBhcnRtZW50TmFtZSI6bnVsbH0sInRva2
      // VuIjoiZXlKaGJHY2lPaUpJVXpJMU5pSXNJblI1Y0NJNklrcFhWQ0o5LmV5SmZhV1FpT2lJMk56TTJPV1l4WXpjNE1USmxabVEwWmpjeE0yUm1abUlpTENKMWMyVnlibUZ0WlNJNkluTnBZ
      // VzB1ZDJGdWJtRnJiM05wZEVCMGNuVmxZMjl5Y0M1amJ5NTBhQ0lzSW1aMWJHeE9ZVzFsSWpvaVUybGhiUzUzSWl3aWNHRnpjM2R2Y21RaU9pSWtNbUlrTVRJa2VXTkxZMHRpYkROVGNrVkN
      // SVmhXUTFoek5VZElUMlJ0VWpNMk4xaDFjbUpNZVdkUlVWSXhTRFZYTDBRd1IwVXZTMDFGVm1FaUxDSmtaWEJoY25SdFpXNTBJanB1ZFd4c0xDSnliMnhsY3lJNld5SXdJbDBzSW1GamRHbD
      // JZWFJsWkNJNmRISjFaU3dpWTNKbFlYUmxWR2x0WlNJNk1UY3pNVFl6TWpreU5ERXhNeXdpZFhCa1lYUmxWR2x0WlNJNk1UY3pNVFl6TWpreU5ERXhNeXdpWDE5Mklqb3dMQ0p6WTI5d1pYT
      // WlPbHNpWVdSdGFXNHVZM0psWVhSbElpd2lZV1J0YVc0dVpXUnBkQ0lzSW1Ga2JXbHVMblpwWlhjaUxDSmhaRzFwYmk1allXNWpaV3dpTENKamRYTjBiMjFsY2k1amNtVmhkR1VpTENKamRY
      // TjBiMjFsY2k1bFpHbDBJaXdpWTNWemRHOXRaWEl1ZG1sbGR5SXNJbU4xYzNSdmJXVnlMbU5oYm1ObGJDSXNJbXB2WWk1amNtVmhkR1VpTENKcWIySXVaV1JwZENJc0ltcHZZaTUyYVdWM0l
      // pd2lhbTlpTG1OaGJtTmxiQ0lzSW5KbGNHOXlkQzVqY21WaGRHVWlMQ0p5WlhCdmNuUXVaV1JwZENJc0luSmxjRzl5ZEM1MmFXVjNJaXdpY21Wd2IzSjBMbU5oYm1ObGJDSmRMQ0p5YjJ4bG
      // MwUmxjMk55YVhCMGFXOXVJanBiZXlKZmFXUWlPaUl3SWl3aWJtRnRaU0k2SWtGa2JXbHVhWE4wY21GMGIzSWdVbTlzWlNKOVhTd2laR1Z3WVhKMGJXVnVkRTVoYldVaU9tNTFiR3dzSW1sa
      // GRDSTZNVGN6TVRZMk9UQTROaXdpWlhod0lqb3hOek14TmprM09EZzJmUS52RkJBenRQekZHd3ZhSWFyRFBUaW50T2p3VzhKOFdPWG1sNzJZNHh2Y04wIn0%3D';

      const sessionId = getCookie('acs-token');

      if (!isEmpty(sessionId)) {
        await this.$store.dispatch('auth/userToken', sessionId);
        if (this.isAuth) {
          this.$router.push({ name: 'Dashboard' });
        }
      } else {
        // deleteCookie('acs-token');
        this.$router.push({ name: 'Login' });
      }
    });
  },

  data() {
    return {};
  },

  computed: {
    ...mapGetters({
      isAuth: 'auth/IS_AUTHENTICATED',
    }),
  },

  methods: {},
};
</script>

<style></style>
